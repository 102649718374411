$('#id_date_time input').on('change', function () {
    var time = $(this).val();
    var type = $('#current_type').val();
    var sensor_id = $('#sensor_id').val();
    if (type == 'day') {
        $.post('/barDay/', {'sensor_id': sensor_id, 'type': type, 'time': time}, function (data) {
            $('#reading_chart').highcharts(data);

            $('#reading_table').dataTable({
                'destroy': true,
                'searching': false,
                'ajax': {
                    'url': '__api/update_datatable/',
                    'type': 'POST',
                    'data': {'series': JSON.stringify(data.series), 'time': time, 'type': type}
                },
                'columns': [
                    null,
                    null
                ]
            });
        });
    }
    else if (type == 'week') {
        $.post('/bar/', {'sensor_id': sensor_id, 'type': type, 'time': time}, function (data) {
            $('#reading_chart').highcharts(data);

            $('#reading_table').dataTable({
                'destroy': true,
                'searching': false,
                'ajax': {
                    'url': '__api/update_datatable/',
                    'type': 'POST',
                    'data': {'series': JSON.stringify(data.series), 'time': time, 'type': type}
                },
                'columns': [
                    null,
                    null
                ]
            });
        });
    }
});
